// Generated by Framer (ddd30d5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Ad9bGzcFR", "B4lexUVFJ"];

const serializationHash = "framer-BHqP5"

const variantClassNames = {Ad9bGzcFR: "framer-v-1bgotnj", B4lexUVFJ: "framer-v-19o0wrj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Light OFF": "Ad9bGzcFR", "Light ON": "B4lexUVFJ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Ad9bGzcFR"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Ad9bGzcFR", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1bgotnj", className, classNames)} data-framer-name={"Light OFF"} layoutDependency={layoutDependency} layoutId={"Ad9bGzcFR"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-eeb779af-c71c-4f8f-9617-63883dd85a06, rgb(42, 45, 49))", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", ...style}} variants={{B4lexUVFJ: {backgroundColor: "var(--token-ed3b63a2-509e-4d49-8b32-4225b3c51ff5, rgb(95, 99, 108))"}}} {...addPropertyOverrides({B4lexUVFJ: {"data-framer-name": "Light ON"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BHqP5.framer-hw6wmv, .framer-BHqP5 .framer-hw6wmv { display: block; }", ".framer-BHqP5.framer-1bgotnj { height: 1px; position: relative; width: 1px; }", ".framer-BHqP5.framer-v-19o0wrj.framer-1bgotnj { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 1px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 1
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"B4lexUVFJ":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramersH6gFdE5c: React.ComponentType<Props> = withCSS(Component, css, "framer-BHqP5") as typeof Component;
export default FramersH6gFdE5c;

FramersH6gFdE5c.displayName = "Abstract/Dot Animation Light";

FramersH6gFdE5c.defaultProps = {height: 1, width: 1};

addPropertyControls(FramersH6gFdE5c, {variant: {options: ["Ad9bGzcFR", "B4lexUVFJ"], optionTitles: ["Light OFF", "Light ON"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramersH6gFdE5c, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})